<template>
    <div class="row g-5 g-xl-8">
        <div class="col-xl-6">
            <custom-table
                :title="$t('pages.ecommerce.productManagement.variant.group.title')"
                :subTitle="$t('pages.ecommerce.productManagement.variant.group.subTitle')"
                selectableRows
                rowKey="id"
                :items="variantGroups.data"
                :loading="variantGroups.loading"
                :pagination="variantGroups.pagination"
                :columns="variantGroup.fields"
                :actions="variantGroup.actions"
                @action="variantGroupHandleClickAction"
                @selectableRows="variantGroupHandleSelectedRow"
                @changeTable="variantGroupHandleTableChange">
                <template v-slot:status="{ row: record }">
                    <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
                </template>
                <template v-slot:actions="{ row: record, rowIndex: rowGroupIndex }">
                   <div class="d-flex justify-content-end">
                       <el-tooltip :content="$t('pages.ecommerce.productManagement.variant.group.items')" placement="top">
                           <a v-on:click="showVariantGroupIndex(rowGroupIndex)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" :class="{'showedVariantItem': variantGroup.showedGroupItemIndex == rowGroupIndex}">
                               <span class="svg-icon svg-icon-3">
                                   <inline-svg src="/media/icons/duotune/general/gen019.svg" fill="white"/>
                               </span>
                           </a>
                       </el-tooltip>
                       <a v-on:click="fetchVariantGroup(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-if="record.is_editable">
                           <span class="svg-icon svg-icon-3">
                               <inline-svg src="/media/icons/duotune/art/art005.svg" />
                           </span>
                       </a>
                       <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteVariantGroupRecord([record.id])" v-if="record.is_deletable">
                           <template #reference>
                               <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                   <span class="svg-icon svg-icon-3">
                                       <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                   </span>
                               </a>
                           </template>
                       </el-popconfirm>
                   </div>
                </template>
            </custom-table>
        </div>
        <div class="col-xl-6">
            <custom-table
                :title="$t('pages.ecommerce.productManagement.variant.item.title')"
                :subTitle="$t('pages.ecommerce.productManagement.variant.item.subTitle')"
                selectableRows
                rowKey="id"
                :items="variantItems.data"
                :loading="variantItems.loading"
                :pagination="variantItems.pagination"
                :columns="variantItem.fields"
                :actions="variantItem.actions"
                @action="variantItemHandleClickAction"
                @selectableRows="variantItemHandleSelectedRow"
                @changeTable="variantItemHandleTableChange">
                <template v-slot:status="{ row: record }">
                    <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
                </template>
                <template v-slot:actions="{ row: record }">
                    <div class="d-flex justify-content-end">
                        <a v-on:click="fetchVariantItem(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-if="record.is_editable">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/art/art005.svg" />
                            </span>
                        </a>
                        <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteVariantItemRecord([record.id])"  v-if="record.is_deletable">
                            <template #reference>
                                <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                </span>
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </custom-table>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_add_variant_group" ref="addVariantGroupModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ variantGroup.form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onVariantGroupSubmit()" :model="variantGroup.form.data" ref="variantGroupForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.group.cols.code') }}</label>
                                <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="variantGroup.form.data.code" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.group.cols.name') }}</label>
                                <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="variantGroup.form.data.name" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.group.cols.allowedGroups') }}</label>
                                <el-form-item prop="allowed_groups" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="variantGroup.form.data.allowed_groups" multiple class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(group, groupIndex) in productGroups" :key="groupIndex" :value="group.id" :label="group.name">{{ group.name }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.group.cols.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="variantGroup.form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.group.cols.status') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="variantGroup.form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="variantGroup.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="variantGroup.form.loading">
                            <span v-if="!variantGroup.form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="variantGroup.form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_add_variant_item" ref="addVariantItemModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_variant_item_header">
                    <h2 class="fw-bolder">{{ variantItem.form.title }}</h2>
                    <div id="kt_modal_add_variant_item_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onVariantItemSubmit()" :model="variantItem.form.data" ref="variantItemForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_variant_item_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.item.cols.code') }}</label>
                                <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="variantItem.form.data.code" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.item.cols.name') }}</label>
                                <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="variantItem.form.data.name" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.item.cols.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="variantItem.form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.variant.item.cols.status') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="variantItem.form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="variantItem.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="variantItem.form.loading">
                            <span v-if="!variantItem.form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="variantItem.form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            variantGroup:{
                fields: [
                    {
                        name: this.$t("pages.ecommerce.productManagement.variant.group.cols.name"),
                        key: "name"
                    },
                    {
                        name: this.$t("pages.ecommerce.productManagement.variant.group.cols.sort"),
                        key: "sort"
                    },
                    {
                        name: this.$t("pages.ecommerce.productManagement.variant.group.cols.status"),
                        scopedSlots: {customRender: "status"}
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
                actions: [
                    {
                        name: "new",
                        icon: "bi-plus-lg",
                        label: "btn.add"
                    },
                    {
                        name: "refresh",
                        icon: "bi-arrow-repeat",
                        label: "btn.refresh"
                    },
                    {
                        name: "delete",
                        icon: "bi-trash",
                        label: "btn.delete"
                    }
                ],
                form: {
                    title: '',
                    loading: false,
                    updateStatus: false,
                    data: {
                        allowed_groups: []
                    }
                },
                showedGroupItemIndex: undefined,
                selectedRowKeys: []
            },
            variantItem:{
                fields: [
                    {
                        name: this.$t("pages.ecommerce.productManagement.variant.item.cols.name"),
                        key: "name"
                    },
                    {
                        name: this.$t("pages.ecommerce.productManagement.variant.item.cols.sort"),
                        key: "sort"
                    },
                    {
                        name: this.$t("pages.ecommerce.productManagement.variant.item.cols.status"),
                        scopedSlots: {customRender: "status"}
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
                actions: [
                    {
                        name: "new",
                        icon: "bi-plus-lg",
                        label: "btn.add"
                    },
                    {
                        name: "refresh",
                        icon: "bi-arrow-repeat",
                        label: "btn.refresh"
                    },
                    {
                        name: "delete",
                        icon: "bi-trash",
                        label: "btn.delete"
                    }
                ],
                form: {
                    title: '',
                    loading: false,
                    updateStatus: false,
                    data: {}
                },
                table: {
                    loading: true,
                    data: []
                },
                selectedRowKeys: []
            }
        }
    },
    computed: {
        productGroups(){
            return this.$store.state.ecommerce.productManagement.product.group.table.data;
        },
        variantGroups() {
            return this.$store.state.ecommerce.productManagement.variant.group.table;
        },
        variantItems() {
            return this.$store.state.ecommerce.productManagement.variant.item.table;
        },
        selectedVariantGroup(){
            let group = {}

            if(this.variantGroups.data.length){
                group = this.variantGroups.data[this.variantGroup.showedGroupItemIndex];
            }

            return group;
        }
    },
    created(){
        this.$store.dispatch('ecommerce/productManagement/variant/group/get', {page: {}});
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.variants"), [this.$t("menu.productManagement")]);
        this.$store.dispatch('ecommerce/productManagement/product/group/get', {page: {}}).then(()=>{
            this.showVariantGroupIndex(0);
        });
    },
    methods:{
        newVariantGroup(){
            this.variantGroup.form.updateStatus = false;
            this.variantGroup.form.data = {
                allowed_groups: [],
                sort: 1,
                active: true
            };
            this.variantGroup.form.title = this.$t("pages.ecommerce.productManagement.variant.group.newVariantGroup");
            this.showModal(this.$refs.addVariantGroupModal);
        },
        fetchVariantGroup(record){
            this.variantGroup.form.updateStatus = true;
            this.variantGroup.form.title =  this.$t("pages.ecommerce.productManagement.variant.group.editVariantGroup")
            this.axios.get(this.endpoints['ecommerce_product_variant_group']+ '/' + record.id).then(response => {
                this.variantGroup.form.data = response.data.data;
                this.showModal(this.$refs.addVariantGroupModal);
            });
        },
        onVariantGroupSubmit(){
            this.$refs.variantGroupForm.validate((valid) => {
                if(valid) {
                    this.variantGroup.form.loading = true;

                    if(this.variantGroup.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_product_variant_group'] + '/' + this.variantGroup.form.data.id, this.variantGroup.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshVariantGroupTable();
                                this.hideModal(this.$refs.addVariantGroupModal);
                            }, () => {
                                this.variantGroup.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.variantGroup.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['ecommerce_product_variant_group'], this.variantGroup.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshVariantGroupTable();
                                this.hideModal(this.$refs.addVariantGroupModal);
                            }, () => {
                                this.variantGroup.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.variantGroup.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        refreshVariantGroupTable(){
            this.$store.dispatch("ecommerce/productManagement/variant/group/refresh");
        },
        variantGroupHandleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshVariantGroupTable();
                    break;

                case "new":
                    this.newVariantGroup();
                    break;

                case "delete":
                    this.deleteVariantGroupRecord(this.variantGroup.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        variantGroupHandleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/productManagement/variant/group/get", {
                page: pagination,
                filterData: filterData
            });
        },
        variantGroupHandleSelectedRow(record){
            this.variantGroup.selectedRowKeys = record;
        },
        deleteVariantGroupRecord(id){
            this.$store.dispatch("ecommerce/productManagement/variant/group/delete", {
                id: id
            }).then((successDeleted) => {
                this.variantGroup.selectedRowKeys = this.variantGroup.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if(successDeleted) {
                    this.refreshVariantItemTable();
                }
            });
        },
        showVariantGroupIndex(index) {
            this.variantGroup.showedGroupItemIndex = index;
            this.$store.dispatch('ecommerce/productManagement/variant/item/get', {
                page: {},
                filterData: {
                    group_id: this.selectedVariantGroup.id
                }
            });
        },
        newVariantItem(){
            this.variantItem.form.updateStatus = false;
            this.variantItem.form.data = {
                group_id: this.selectedVariantGroup.id,
                sort: 1,
                active: true
            };
            this.variantItem.form.title = this.$t("pages.ecommerce.productManagement.variant.item.newVariantItem");
            this.showModal(this.$refs.addVariantItemModal);
        },
        fetchVariantItem(record){
            this.variantItem.form.updateStatus = true;
            this.variantItem.form.title =  this.$t("pages.ecommerce.productManagement.variant.group.editVariantGroup")
            this.axios.get(this.endpoints['ecommerce_product_variant_item']+ '/' + record.id).then(response => {
                this.variantItem.form.data = response.data.data;
                this.showModal(this.$refs.addVariantItemModal);
            });
        },
        onVariantItemSubmit(){
            this.$refs.variantItemForm.validate((valid) => {
                if(valid) {
                    this.variantItem.form.loading = true;

                    if(this.variantItem.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_product_variant_item'] + '/' + this.variantItem.form.data.id, this.variantGroup.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshVariantItemTable();
                                this.hideModal(this.$refs.addVariantItemModal);
                            }, () => {
                                this.variantItem.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.variantItem.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['ecommerce_product_variant_item'], this.variantItem.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshVariantItemTable();
                                this.hideModal(this.$refs.addVariantItemModal);
                            }, () => {
                                this.variantItem.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.variantItem.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        refreshVariantItemTable(){
            this.$store.dispatch("ecommerce/productManagement/variant/item/refresh");
        },
        variantItemHandleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshVariantItemTable();
                    break;

                case "new":
                    this.newVariantItem();
                    break;

                case "delete":
                    this.deleteVariantItemRecord(this.variantItem.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        variantItemHandleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/productManagement/variant/item/get", {
                page: pagination,
                filterData: filterData
            });
        },
        variantItemHandleSelectedRow(record){
            this.variantItem.selectedRowKeys = record;
        },
        deleteVariantItemRecord(id){
            this.$store.dispatch("ecommerce/productManagement/variant/item/delete", {
                id: id
            }).then((successDeleted) => {
                this.variantItem.selectedRowKeys = this.variantItem.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
    }
}
</script>

<style>
    .btn.showedVariantItem {
        background-color: #1da1f2 !important;
        transition: none !important;
    }
    .btn.showedVariantItem .svg-icon svg [fill]:not(.permanent):not(g){
        transition: none !important;
        fill: #FFFFFF !important;
    }
</style>